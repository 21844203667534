import { DUE_DILIGENCE_LEVEL } from '../../../../_core/constants/OrganizationFormControls';
import { UUIDName } from '../../../../_core/models/GenericObject';
import { Media } from '../../../../_core/models/FormControls';

export class Champion {
  champion?: UUIDName;
  dueDiligenceLevel: DUE_DILIGENCE_LEVEL;
  grantsAwarded: number;
  grantsAwardedCurrency: UUIDName;
  quote: string;
  videoPresentation: Media;
  historyDueDiligence: string;
  dueDiligenceReport: boolean;
  dueDiligenceFile: Media;
  impactStory: string;
  topRisks: string;

  constructor(champion: IChampion) {
    this.champion = new UUIDName(champion.uuid, '');
    this.dueDiligenceLevel = champion.dueDiligenceLevel;
    this.grantsAwarded = champion.grantsAwardedNumber;
    this.grantsAwardedCurrency = champion.currency;
    this.quote = champion.quote;
    this.videoPresentation = champion.video;
    this.historyDueDiligence = champion.history;
    this.dueDiligenceReport = !!champion.document;
    this.dueDiligenceFile = champion.document;
    this.impactStory = champion.impactStory;
    this.topRisks = champion.topRisks;
  }
}

export interface LinkChampionPayload {
  organisationUuid?: string;
  projectUuid?: string;
  portfolioUuid?: string;
  championLinkedEntityData: IChampion;
}

export interface IChampion {
  currency: UUIDName;
  document: Media;
  dueDiligenceLevel: DUE_DILIGENCE_LEVEL;
  email?: string;
  grantsAwardedNumber: number;
  history: string;
  impactStory: string;
  quote: string;
  topRisks: string;
  uuid: string;
  video: Media;
}
