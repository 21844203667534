import { EnvironmentName, TenantId } from 'src/app/_core/constants/EnvironmentConst';
import { Environment } from 'src/app/_core/models/IEnvironment';

export const environment: Environment = {
  name: EnvironmentName.PCP,
  tenantId: TenantId.PCP_TEST,
  defaultLanguage: 'en-pcp',
  appVersion: require('../../package.json').version,
  production: true,
  url: 'https://test.pcp.givingspace.com/',
  server: 'https://test.pcp.givingspace.com/api',
  authServer: 'https://test.pcp.givingspace.com/api-auth',
  mattermostUrl: 'https://mattermost.pcpcommunity.com/login',
  placesApiKey: 'AIzaSyCDVRL0Igtki_L6-xHqyquPHdLZ3D3GpXA',
  recaptchaApiKey: '6Ld9tNYfAAAAAInURAhAM34V5OmNg74xWnH4Ib19',
  favicon: 'assets/env-assets/pcp/favicon.JPG',
  authBackground: 'assets/env-assets/pcp/bg.jpg',
  whiteLogo: 'assets/env-assets/pcp/pcp-logo.jpg',
  blackLogo: 'assets/env-assets/pcp/pcp-logo.jpg',
  termsOfUse: 'https://www.trustbridgeglobal.com/terms',
  privacyPolicy: 'https://www.trustbridgeglobal.com/privacy',
  emailAddress: 'admin@pcpcommunity.com',
  hasCause: true,
  hasCodeOfConduct: true,
  isEnglishDefault: true,
};
