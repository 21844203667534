import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EntityMainInfo } from '../models/GenericObject';
import { CatalogDropdownFilterSearch } from '../models/filters/CatalogFilters';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  private changeEntityStatus = new BehaviorSubject<EntityMainInfo>(null);
  changeEntityStatus$ = this.changeEntityStatus.asObservable();

  private catalogDropdownFilterSearch = new BehaviorSubject<CatalogDropdownFilterSearch>(null);
  catalogDropdownFilterSearch$ = this.catalogDropdownFilterSearch.asObservable();

  setEntityValue(entity: EntityMainInfo): void {
    this.changeEntityStatus.next(entity);
  }

  setDropDownFilterSearchText(searchTerms: CatalogDropdownFilterSearch): void {
    this.catalogDropdownFilterSearch.next(searchTerms);
  }
}
