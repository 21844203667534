import { EntityStatus } from '../../constants/CompanyOrFirm';
import { FILTER_NAME } from '../../constants/Filters/FilterTypes';
import { CodeValue } from '../GenericObject';
import { Filter, Filters } from './Filters';
import { PortfolioType } from '../portfolio/Portoflio';

export class AdminOrganizationFilters {
  projectsStatuses: Filter;

  constructor() {
    this.projectsStatuses = new Filter(FILTER_NAME.PROJECTS_STATUSES, EntityStatus);
  }
}

export class AdminProjectFilters extends AdminOrganizationFilters {}

export class AdminPortfolioFilters {
  statusList: Filter;
  archived: Filter;
  portfolioType: Filter;

  constructor() {
    this.statusList = new Filter(FILTER_NAME.STATUS, EntityStatus);
    this.archived = new Filter(FILTER_NAME.ARCHIVED, new CodeValue('archived', false), { singleValue: true });
    this.portfolioType = new Filter(FILTER_NAME.PORTFOLIO_TYPE, PortfolioType);
  }
}

export class CatalogFilters {
  regions: Filter;
  countries: Filter;
  causes: Filter;
  categories: Filter;
  champions: Filter;

  constructor(filters: Filters) {
    this.regions = new Filter(FILTER_NAME.CHARITIES_REGION, filters.regions);
    this.countries = new Filter(FILTER_NAME.COUNTRY, filters.countries);
    this.causes = new Filter(FILTER_NAME.CAUSE, filters.causes);
    this.categories = new Filter(FILTER_NAME.CHARITIES_CATEGORY, filters.categories);
    this.champions = new Filter(FILTER_NAME.CHARITIES_CHAMPIONED, filters.champions);
  }
}

export class MyChampionedFilters {
  projectType: Filter;

  constructor(filters) {
    this.projectType = new Filter(FILTER_NAME.TYPE, new CodeValue(filters.projectType), { singleValue: true });
  }
}

export class CatalogDropdownFilterSearch {
  filterType: string;
  searchText: string;

  constructor(filterType: string, searchText: string) {
    this.filterType = filterType;
    this.searchText = searchText;
  }
}
