<div class="align-items-center d-flex mb-1" *ngIf="!isMobile && !collapsed; else collapsedMenu">
  <ng-container *ngFor="let key of filters | objectIteration">
    <button
      *ngIf="filters[key].options.length && (filters[key].adminOnly === undefined || filters[key].adminOnly === isAdmin)"
      class="font-weight-normal no-outline arrow filter-button"
      mat-button
      [matMenuTriggerFor]="menu"
      [disableRipple]="true"
    >
      {{ 'GENERAL.FILTERS.' + filters[key].name | translate | uppercase }}
      <span
        class="filter-badge"
        [matBadge]="countFiltersApplied(filters[key].options) !== 0 ? countFiltersApplied(filters[key].options) : ''"
        matBadgeOverlap="true"
        matBadgeSize="small"
        matBadgeColor="primary"
      ></span>
      <span class="material-icons" [ngClass]="{ 'ml-3': countFiltersApplied(filters[key].options) !== 0 }">keyboard_arrow_down</span>
    </button>
    <mat-menu #menu="matMenu" xPosition="after" yPosition="below">
      <ng-container *ngIf="!filters[key].singleValue">
        <div class="admin-input search-box-container d-flex">
          <mat-form-field appearance="outline">
            <i class="fas fa-search icon mr-2"></i>
            <input matInput [formControl]="searchInput" placeholder="Search" (click)="onSelectFilter($event, key)" />
          </mat-form-field>
        </div>
        <ng-container *ngFor="let item of filters[key].options">
          <ng-container *ngIf="item.code === 'No record found'; else withRecord">
            <div class="state p-primary mt-3 text-center">
              <label>
                <div style="display: inline-flex">
                  <span style="text-indent: 5px">
                    {{ item.code | uppercase }}
                  </span>
                </div>
              </label>
            </div>
          </ng-container>
          <ng-template #withRecord>
            <mat-option *ngIf="item.code | translate" (click)="!item.readonly ? selectOption(filters[key], item, $event) : null">
              <div class="pretty p-default">
                <input type="checkbox" [ngModel]="item.checked" [disabled]="item.readonly" />
                <div class="state p-primary" [style.margin-bottom]="item.imageUrl ? '-3px' : ''">
                  <label>
                    <div style="display: inline-flex">
                      <img *ngIf="item.imageUrl" src="assets/icons/verified-champion.svg" width="13px" alt="verified" />
                      <span style="text-indent: 5px">
                        {{ item.code | uppercase }}
                      </span>
                    </div>
                  </label>
                </div>
              </div>
            </mat-option>
          </ng-template>
        </ng-container>
      </ng-container>
    </mat-menu>
  </ng-container>
  <div
    class="d-flex align-items-center pointer ml-2 mt-1 px-2 hoverable-light-grey expanded-reset-btn"
    (click)="resetFilters()"
    *ngIf="filterCategoriesApplied !== 0"
  >
    <mat-icon class="mr-1 small-clear-icon text-danger">clear</mat-icon>
    {{ 'GENERAL.FILTERS.RESET_FILTERS' | translate | titlecase }}
  </div>
</div>

<ng-template #collapsedMenu>
  <div class="d-flex align-items-center h-100">
    <button
      class="no-outline arrow m-0 p-0 d-flex align-items-center justify-content-center"
      mat-button
      [matMenuTriggerFor]="filterCategories"
      [disableRipple]="true"
      *ngIf="displayFilter"
    >
      <img src="assets/icons/iconfinder_filter.svg" />
      <span
        class="button-badge"
        [matBadge]="filterCategoriesApplied !== 0 ? filterCategoriesApplied : ''"
        matBadgeOverlap="true"
        matBadgeSize="small"
        matBadgeColor="primary"
      ></span>
    </button>
  </div>

  <mat-menu #filterCategories="matMenu">
    <ng-container *ngFor="let key of filters | objectIteration">
      <ng-container *ngIf="!filters[key].singleValue">
        <ng-container *ngIf="filters[key].name !== filterType.SEARCH_BY_ORGANISATION; else searchFilter">
          <mat-expansion-panel
            *ngIf="filters[key].options?.length && (filters[key].adminOnly === undefined || filters[key].adminOnly === isAdmin)"
            (click)="$event.stopPropagation()"
          >
            <mat-expansion-panel-header>
              {{ 'GENERAL.FILTERS.' + filters[key].name | translate | uppercase }}
              <span
                class="collapsed-filter-badge"
                [matBadge]="countFiltersApplied(filters[key].options) !== 0 ? countFiltersApplied(filters[key].options) : ''"
                matBadgeOverlap="true"
                matBadgeSize="small"
                matBadgeColor="primary"
              ></span>
            </mat-expansion-panel-header>
            <ng-container *ngIf="filters[key].options?.length">
              <ng-container *ngFor="let item of filters[key].options">
                <mat-option
                  class="filter-option"
                  *ngIf="item.code | translate"
                  (click)="!item.readonly ? selectOption(filters[key], item, $event) : null"
                >
                  <div class="pretty p-default">
                    <input type="checkbox" [ngModel]="item.checked" [disabled]="item.readonly" />
                    <div class="state p-primary" [style.margin-bottom]="item.imageUrl ? '-3px' : ''">
                      <label>
                        <div style="display: inline-flex">
                          <img *ngIf="item.imageUrl" src="assets/icons/verified-champion.svg" width="13px" alt="verified" />
                          <span style="text-indent: 5px">
                            {{ item.code | translate | uppercase }}
                          </span>
                        </div>
                      </label>
                    </div>
                  </div>
                </mat-option>
              </ng-container>
            </ng-container>
          </mat-expansion-panel>
        </ng-container>
        <ng-template #searchFilter>
          <mat-expansion-panel *ngIf="isAdmin" (click)="$event.stopPropagation()">
            <mat-expansion-panel-header>
              {{ 'GENERAL.FILTERS.' + filters[key].name | translate | uppercase }}
              <span
                class="collapsed-filter-badge"
                [matBadge]="countFiltersApplied(filters[key].options) !== 0 ? countFiltersApplied(filters[key].options) : ''"
                matBadgeOverlap="true"
                matBadgeSize="small"
                matBadgeColor="primary"
              ></span>
            </mat-expansion-panel-header>
            <app-search-entity
              [control]="inputField"
              [required]="false"
              suffixIcon="search"
              (searchRequest)="selectInput(filters[key], inputField.value, $event)"
            ></app-search-entity>
          </mat-expansion-panel>
        </ng-template>
      </ng-container>
    </ng-container>
    <div
      class="d-flex align-items-center pl-3 py-12 font-15 pointer hoverable-light-grey"
      (click)="resetFilters()"
      *ngIf="filterCategoriesApplied !== 0"
    >
      <mat-icon class="clear-icon mr-2 ml-1 text-danger">clear</mat-icon>
      {{ 'GENERAL.FILTERS.RESET_FILTERS' | translate | uppercase }}
    </div>
  </mat-menu>
</ng-template>
