import { Media } from '../FormControls';
import { EntityStatus } from '../../constants/CompanyOrFirm';
import { ArrayObjects, EntityMainInfo, NavigationData, UUIDName } from '../GenericObject';
import { AdminPortfolioCardDto, PortfolioDto, PortfolioItemDto } from './PortfolioDto';
import { Language } from '../global-settings/Language';
import Urls from '../../constants/Urls';
import { Champion } from '../../../internal-app/details/champion/models/Champion';

export enum PortfolioType {
  ALLOCATED = 'ALLOCATED',
  NON_ALLOCATED = 'NON_ALLOCATED',
}

export class AdminPortfolioCard extends EntityMainInfo {
  portfolioType: PortfolioType;
  coverImageUrl: string;
  editPortfolioLink: NavigationData;
  previewPortfolioLink: NavigationData;

  constructor(portfolioData: AdminPortfolioCardDto) {
    super(portfolioData.uuid, portfolioData.name, portfolioData.entityStatus);
    this.coverImageUrl = portfolioData.urlCoverImage || portfolioData.urlThumbnail;
    this.portfolioType = portfolioData.portfolioType;
    // this.setSelectedLanguage(portfolioData.language);
    this.editPortfolioLink = new NavigationData(
      `/${Urls.APP}/${Urls.SETTINGS}/${Urls.SETUP}/${Urls.PORTFOLIOS}/${Urls.UPDATE_PORTFOLIO}/${this.uuid}`
    );
    this.previewPortfolioLink = new NavigationData(`/${Urls.APP}/${Urls.DETAILS}/${Urls.PORTFOLIO}/${this.uuid}`, {
      preview: true,
    });
  }
}

export class Portfolio {
  primary: PortfolioPrimaryData;
  champions: Champion[];
  entityStatus: EntityStatus;
  language: Language;
  mandatoryProfile: UUIDName;
  organisationPositionFirst: boolean;

  constructor(portfolio: PortfolioDto) {
    this.primary = {
      categories: portfolio.categories.map((option) => new UUIDName(option.uuid, option.name)),
      cause: portfolio.cause ? new UUIDName(portfolio.cause.uuid, portfolio.cause.name) : null,
      countries: portfolio.countries.map((option) => new UUIDName(option.uuid, option.name)),
      description: portfolio.description,
      givingLink: portfolio.givingLink,
      media: new PortfolioMedia(portfolio.coverImage, portfolio.video, portfolio.thumbnail),
      name: portfolio.name,
      organisations: portfolio.organisations.map((item: PortfolioItemDto, index: number) => new PortfolioItem({ ...item, position: index + 1 })),
      type: portfolio.portfolioType,
      private: portfolio.private,
      projects: portfolio.projects.map((item: PortfolioItemDto, index: number) => new PortfolioItem({ ...item, position: index + 1 })),
      uuid: portfolio.uuid,
      portfolioProfile: portfolio.mandatoryProfile,
      portfolioRank: portfolio.portfolioScore,
    };
    this.entityStatus = portfolio.entityStatus;
    this.language = portfolio.language;
    this.champions = portfolio.champions.map((champion) => new Champion(champion));
    this.mandatoryProfile = portfolio.mandatoryProfile;
    this.organisationPositionFirst = portfolio.organisationPositionFirst;
  }
}

export interface PortfolioPrimaryData {
  categories: UUIDName[];
  cause: UUIDName;
  countries: UUIDName[];
  description: string;
  givingLink: string;
  media: PortfolioMedia;
  name: string;
  organisations: PortfolioItem[];
  type: PortfolioType;
  private: boolean;
  projects: PortfolioItem[];
  uuid: string;
  portfolioProfile: UUIDName;
  portfolioRank: number;
}

export class PortfolioItem {
  item: UUIDName;
  allocation: number;
  position: number;

  constructor(portfolioItem: PortfolioItemDto) {
    this.item = new UUIDName(portfolioItem.uuid, portfolioItem.name);
    this.allocation = portfolioItem.percent;
    this.position = portfolioItem.position;
  }
}

export class PortfolioMedia {
  image: Media;
  video: Media;
  thumbnail: Media;

  constructor(image: Media, video: Media, thumbnail: Media) {
    this.image = image;
    this.video = video;
    this.thumbnail = thumbnail;
  }
}

export class PortfolioDropdowns {
  causes: UUIDName[];
  categories: UUIDName[];
  champions: UUIDName[];
  countries: UUIDName[];
  currencies: UUIDName[];
  projects: UUIDName[];
  organisations: UUIDName[];

  constructor(dropdowns: PortfolioDropdowns) {
    this.causes = dropdowns.causes.map((option) => new UUIDName(option.uuid, option.name));
    this.categories = dropdowns.categories.map((option) => new UUIDName(option.uuid, option.name));
    this.champions = dropdowns.champions.map((option) => new UUIDName(option.uuid, option.name));
    this.countries = dropdowns.countries.map((option) => new UUIDName(option.uuid, option.name));
    this.currencies = dropdowns.currencies.map((option) => new UUIDName(option.uuid, option.name));
    this.projects = dropdowns.projects.map((option) => new UUIDName(option.uuid, option.name));
    this.organisations = dropdowns.organisations.map((option) => new UUIDName(option.uuid, option.name));
  }
}

export interface CreateUpdatePortfolioPayload {
  categories: string[];
  cause: string;
  champions: string[];
  collection: CollectionPayload[];
  countries: string[];
  coverImage: string;
  description: string;
  givingLink: string;
  languageCode: string;
  modifiedFields: ArrayObjects;
  name: string;
  portfolioType: PortfolioType;
  private: boolean;
  submitted: boolean;
  thumbnail: string;
  uuid: string;
  video: string;
  mandatoryProfileUuid: string;
  portfolioScore: number;
  organisationPositionFirst: boolean;
}

export interface CollectionPayload {
  percent: number;
  organisationUuid?: string;
  projectUuid?: string;
  projectPosition?: number;
  organisationPosition?: number;
}
