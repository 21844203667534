export enum Urls {
  BASE = '/',

  MAINTENANCE = 'maintenance',

  AUTH = 'auth',
  LOGIN = 'login',
  REGISTER = 'register',
  FORGOT_PASSWORD = 'forgot-password',
  RESET_PASSWORD = 'reset-password',
  CONFIRM_EMAIL = 'confirm-email',
  CONFIRM_EMAIL_UPDATE = 'confirm-email-update',

  APP = 'app',
  HOMEPAGE = 'home',
  LEARN_MORE = 'learn-more',
  FAQ = 'faqs',
  ACTIVITY = 'activity',
  CODE_OF_CONDUCT = 'code-of-conduct',

  USER_ACCOUNT = 'user-account',
  NEW = 'new',

  USER = 'user',
  USER_INFO = 'user-info',
  MY_INFO = 'my-info',
  USER_SETTINGS = 'user-settings',
  SETTINGS = 'settings',
  MY_FAVORITES = 'my-favorites',
  MY_CHAMPIONED = 'my-championed',

  CATALOG = 'catalog',
  SEARCH_RESULTS = 'search-results',
  CLOSE_TO_CLOSING = 'close-to-closing',

  GIVING = 'giving',

  DETAILS = 'details',
  PREVIEW = 'preview',

  ORGANIZATION = 'organization',
  ORGANISATION = 'organisation',
  PROJECT = 'project',
  PROJECT_ADMIN = 'project-admin',

  SUMMARY_TAB = 'summary',
  TEAM_TAB = 'team',
  FINANCIALS_TAB = 'financials',
  CHAMPION_TAB = 'champion',
  DOCUMENTS_TAB = 'docs',
  QUESTIONS_TAB = 'questions',
  UPDATE_TAB = 'updates',
  PROJECTS_TAB = 'projects',
  GOALS_TAB = 'goals',
  ORGANIZATION_TAB = 'organization',

  PROFILES = 'profiles',
  INVESTORS_AND_CHAMPIONS = 'investors-and-champions',
  PARTNERS = 'partners',
  CHAMPION_PROFILE = 'champion-profile',
  INVESTOR_PROFILE = 'investor-profile',
  PARTNER_PROFILE = 'partner-profile',

  ADMIN = 'admin',
  SETUP = 'setup',
  ORGANIZATIONS = 'organisations',
  PROJECTS = 'projects',
  PORTFOLIOS = 'portfolios',

  NEW_ORGANIZATION = 'new-organization',
  EXISTING_ORGANIZATION = 'existing',
  NEW_PROJECT = 'new-project',
  NEW_PORTFOLIO = 'new-portfolio',

  UPDATE = 'update',
  UPDATE_ORGANIZATION = 'update-organization',
  UPDATE_PROJECT = 'update-project',
  UPDATE_PORTFOLIO = 'update-portfolio',

  ARCHIVE = 'archive',

  USERS_PERMISSIONS = 'users-permissions',

  QUESTIONS_AND_UPDATES = 'questions-and-updates',

  GROUPS = 'groups',
  LIST = 'list',
  ALL_GROUPS = 'all-groups',
  MY_GROUPS = 'my-groups',
  ADD_NEW_GROUP = 'add-new-group',
  EDIT_GROUP = 'edit-group',

  OWNED_GROUPS = 'owned-groups',
  JOINED_GROUPS = 'joined-groups',
  INVITED_TO_JOIN = 'invited-to-join',
  PENDING_REQUEST = 'pending-request',
  MEMBERS = 'members',
  PROJECTS_DISCUSSED = 'projects-discussed',
  ORGANISATIONS_DISCUSSED = 'organisations-discussed',
  DOCUMENTS = 'documents',
  ANNOUNCEMENTS_AND_UPDATES = 'updates-announcements',
  ADD_DOCUMENTS = 'add-documents',
  ADD_NEW_UPDATE = 'add-update',
  EDIT_UPDATE = 'edit-update',
  MESSAGES = 'messages',

  PORTFOLIO = 'portfolio',
  CHAMPIONS = 'champions',
  DESCRIPTION = 'description',

  GLOBAL_SETTINGS = 'global-settings',
  GLOBAL_CONFIG = 'config',
  LANGUAGE_SETTINGS = 'language-settings',
  PROFILES_SETTINGS = 'profiles-settings',
  PROJECT_PROFILES = 'project-profiles',
  ORGANISATION_PROFILES = 'organisation-profiles',
  USER_PROFILES = 'user-profiles',
  BADGES = 'badges',
  HOMEPAGE_CONFIG = 'landing-page-config',
  REGION_SETTINGS = 'region-settings',
  NEW_REGION = 'new-region',
  PROFILE_SETUP = 'profile-setup',
  NEW_PROFILE = 'new-profile',
  NEW_LANGUAGE = 'new-language',
  NEW_BADGE = 'new-badge',
  CHAMPION_ADMIN = 'champion-admin',
  CAUSES_SETTINGS = 'causes-settings',
  NEW_CAUSE = 'new-cause',
  CATEGORIES_SETTINGS = 'categories-settings',
  NEW_CATEGORY = 'new-category',
  PORTFOLIO_PROFILES = 'portfolio-profiles',
  HELP_VIDEOS = 'help-videos',
  NEW_HELP_VIDEO = 'new-help-video',
  IMPORT_EXPORT_CSV = 'import-export-csv',
}

export default Urls;
