import Urls from './Urls';

export enum Privilege {
  USER_ACCOUNT = 'user-account',

  CATALOG_PUBLIC_PROJECTS = 'catalog-public-projects',
  CATALOG_PRIVATE_PROJECTS = 'catalog-private-projects',

  ASK_QUESTION = 'ask-question',
  DASHBOARD = 'dashboard',
  FAQS = 'faqs',

  // ADMIN
  ORGANIZATION_SETUP = 'organization-setup',
  ORGANIZATION_UPDATE = 'organization-update',
  ORGANIZATION_DELETE = 'organization-delete',
  ORGANIZATION_VIEW = 'organization-view',
  ORGANIZATION_LIST = 'organization-list',

  PROJECT_ORGANIZATION_UPDATE = 'project-organization-update',
  PROJECT_SETUP = 'project-setup',
  PROJECT_UPDATE = 'project-update',
  PROJECT_VIEW = 'project-view',
  PROJECT_DELETE = 'project-delete',
  PROJECT_QUESTIONS = 'project-questions',
  PROJECT_LIST = 'project-list',

  PORTFOLIO_PRIVATE_SETUP = 'portfolio-private-setup',
  PORTFOLIO_PUBLIC_SETUP = 'portfolio-public-setup',
  PORTFOLIO_UPDATE = 'portfolio-update',
  PORTFOLIO_VIEW = 'portfolio-view',
  PORTFOLIO_DELETE = 'portfolio-delete',
  PORTFOLIO_LIST = 'portfolio-list',

  USERS_PERMISSIONS = 'users-permissions',
  USER_DELETE = 'user-delete',
  USER_ARCHIVE = 'user-archive',
  USER_UNARCHIVE = 'user-unarchive',

  CHANGE_ENTITY_STATUS = 'change-entity-status',
  PROJECT_IDLE_TO_LIVE = 'project-idle-to-live',

  HAS_FINANCIAL = 'has-financial',

  GROUPS = 'groups',

  GLOBAL_SETTINGS = 'global-settings',
  LANGUAGE_SETUP = 'global-language-setup',
  VETTED = 'vetted',
  CHAMPION_ADMIN = 'champion-admin-pages',
  CHAMPION_CHECKBOX = 'champion-pages',
  HELP_VIDEOS = 'help-videos',

  IMPORT_EXPORT_CSV = 'import-export',
}

export const ModulePrivileges = {
  [Urls.HOMEPAGE]: {
    [Privilege.DASHBOARD]: true,
  },
  [Urls.PREVIEW]: {
    [Privilege.ORGANIZATION_VIEW]: true,
    [Privilege.PROJECT_VIEW]: true,
  },
  [Urls.ORGANIZATION]: {
    [Privilege.ORGANIZATION_VIEW]: true,
  },
  [Urls.ORGANISATION]: {
    [Privilege.ORGANIZATION_VIEW]: true,
  },
  [Urls.PROJECT]: {
    [Privilege.PROJECT_VIEW]: true,
  },
  [Urls.PORTFOLIO]: {
    [Privilege.PORTFOLIO_VIEW]: true,
  },
  [Urls.USER_ACCOUNT]: {
    [Privilege.USER_ACCOUNT]: true,
  },
  [Urls.CATALOG]: {
    [Privilege.CATALOG_PRIVATE_PROJECTS]: true,
    [Privilege.CATALOG_PUBLIC_PROJECTS]: true,
  },
  [Urls.GIVING]: {
    [Privilege.CATALOG_PUBLIC_PROJECTS]: true,
    [Privilege.CATALOG_PRIVATE_PROJECTS]: true,
  },
  [Urls.ADMIN]: {
    [Privilege.ORGANIZATION_SETUP]: true,
    [Privilege.ORGANIZATION_UPDATE]: true,
    [Privilege.ORGANIZATION_LIST]: true,
    [Privilege.PROJECT_SETUP]: true,
    [Privilege.USERS_PERMISSIONS]: true,
    [Privilege.LANGUAGE_SETUP]: true,
    [Privilege.PORTFOLIO_LIST]: true,
    [Privilege.PORTFOLIO_UPDATE]: true,
    [Privilege.PORTFOLIO_PRIVATE_SETUP]: true,
    [Privilege.PORTFOLIO_PUBLIC_SETUP]: true,
    [Privilege.IMPORT_EXPORT_CSV]: true,
  },
  [Urls.SETUP]: {
    [Privilege.ORGANIZATION_SETUP]: true,
    [Privilege.ORGANIZATION_UPDATE]: true,
    [Privilege.PROJECT_SETUP]: true,
    [Privilege.PROJECT_UPDATE]: true,
    [Privilege.PORTFOLIO_PUBLIC_SETUP]: true,
    [Privilege.PORTFOLIO_PRIVATE_SETUP]: true,
    [Privilege.PORTFOLIO_UPDATE]: true,
  },
  [Urls.NEW_ORGANIZATION]: {
    [Privilege.ORGANIZATION_SETUP]: true,
  },
  [Urls.UPDATE_ORGANIZATION]: {
    [Privilege.ORGANIZATION_UPDATE]: true,
  },
  [Urls.NEW_PROJECT]: {
    [Privilege.PROJECT_SETUP]: true,
  },
  [Urls.UPDATE_PROJECT]: {
    [Privilege.PROJECT_UPDATE]: true,
  },
  [Urls.PORTFOLIOS]: {
    [Privilege.PORTFOLIO_LIST]: true,
  },
  [Urls.NEW_PORTFOLIO]: {
    [Privilege.PORTFOLIO_PRIVATE_SETUP]: true,
    [Privilege.PORTFOLIO_PUBLIC_SETUP]: true,
  },
  [Urls.UPDATE_PORTFOLIO]: {
    [Privilege.PORTFOLIO_UPDATE]: true,
  },
  [Urls.ORGANIZATIONS]: {
    [Privilege.ORGANIZATION_LIST]: true,
  },
  [Urls.USERS_PERMISSIONS]: {
    [Privilege.USERS_PERMISSIONS]: true,
  },
  [Urls.ACTIVITY]: {
    [Privilege.PROJECT_VIEW]: true,
    [Privilege.VETTED]: true,
  },
  [Urls.FAQ]: {
    [Privilege.FAQS]: true,
  },
  [Urls.GROUPS]: {
    [Privilege.GROUPS]: true,
  },
  [Urls.GLOBAL_SETTINGS]: {
    [Privilege.GLOBAL_SETTINGS]: true,
    [Privilege.LANGUAGE_SETUP]: true,
  },
  [Urls.LANGUAGE_SETTINGS]: {
    [Privilege.GLOBAL_SETTINGS]: true,
    [Privilege.LANGUAGE_SETUP]: true,
  },
  [Urls.CHAMPION_ADMIN]: {
    [Privilege.CHAMPION_ADMIN]: true,
  },
  [Urls.MY_CHAMPIONED]: {
    [Privilege.CHAMPION_ADMIN]: true,
    [Privilege.CHAMPION_CHECKBOX]: true,
  },
  [Urls.PROJECTS]: {
    [Privilege.PROJECT_LIST]: true,
  },
  [Urls.HELP_VIDEOS]: {
    [Privilege.HELP_VIDEOS]: true,
  },
  [Urls.IMPORT_EXPORT_CSV]: {
    [Privilege.IMPORT_EXPORT_CSV]: true,
  },
};
