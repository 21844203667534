import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FILTER_NAME } from 'src/app/_core/constants/Filters/FilterTypes';
import { Role } from 'src/app/_core/constants/User';
import { UserService } from 'src/app/_core/services/user.service';
import { Filter, FilterOption, generateFilterOptions, IPageFilters } from '../../../_core/models/filters/Filters';
import { UUIDName } from '../../../_core/models/GenericObject';
import { UtilityService } from 'src/app/_core/services/utilities.service';
import { CatalogDropdownFilterSearch } from 'src/app/_core/models/filters/CatalogFilters';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss', '../../../internal-app/catalog/catalog.scss'],
})
export class FiltersComponent implements OnInit, OnDestroy {
  @Input() filters: IPageFilters;
  @Input() collapsed = false;
  @Output() triggerRequest = new EventEmitter<boolean>();

  filterType = FILTER_NAME;
  breakpointSubscription: Subscription;
  isMobile = false;
  displayFilter: boolean;
  searchForm: FormGroup;
  isAdmin: boolean;
  filterCategoriesApplied: number = 0;
  searchInput: FormControl = new FormControl();
  selectedFilterKey: string = '';

  // prettier-ignore
  constructor(
    private breakpointObserver: BreakpointObserver,
    private fb: FormBuilder,
    private userService: UserService,
    private utilitySvc: UtilityService,
  ) {
    this.breakpointSubscription = this.breakpointObserver.observe(['(max-width: 767px)']).subscribe(
      (result) => this.isMobile = result.matches);

    this.searchInput.valueChanges.subscribe((value) => {
      this.utilitySvc.setDropDownFilterSearchText(new CatalogDropdownFilterSearch(this.selectedFilterKey, value));
    });
  }

  ngOnInit(): void {
    this.computeDisplayFilter();
    this.countFiltersCategoriesApplied();
    this.initEntitySearch();
    this.isAdmin = this.userService.currentUser?.role === Role.SUPER_ADMIN;
  }

  ngOnDestroy(): void {
    this.breakpointSubscription.unsubscribe();
  }

  initEntitySearch(): void {
    this.searchForm = this.fb.group({
      inputField: [
        this.filters?.searchByOrganisation?.options?.length
          ? new UUIDName(this.filters.searchByOrganisation.options[0].value as string, this.filters.searchByOrganisation.options[0].code)
          : [],
      ],
    });
  }

  computeDisplayFilter(): void {
    for (const key in this.filters) {
      if (this.filters[key].options?.length || this.filters[key].name === this.filterType.SEARCH_BY_ORGANISATION) {
        this.displayFilter = true;
        break;
      }
    }
  }

  countFiltersApplied(filter: FilterOption[]): number {
    return filter.filter((item) => item.checked !== item.defaultCheck).length;
  }

  countFiltersCategoriesApplied(): void {
    let categoriesCounter = 0;
    for (const key in this.filters) {
      if (this.filters[key].options?.length) {
        if (this.filters[key].options.filter((option: FilterOption) => option.checked !== option.defaultCheck).length) {
          categoriesCounter++;
        }
      }
    }
    this.filterCategoriesApplied = categoriesCounter;
  }

  selectInput(filter: Filter, value: UUIDName, event: Event) {
    filter.data = generateFilterOptions([value]);
    this.selectOption(filter, filter.options[0], event);
  }

  selectOption(filter: Filter, filterOption: FilterOption, event: Event): void {
    filter.selectOption(filterOption);
    event.stopPropagation();
    this.countFiltersCategoriesApplied();
    this.triggerRequest.emit();
  }

  resetFilters(): void {
    for (const key in this.filters) {
      if (this.filters.hasOwnProperty(key)) {
        this.filters[key].resetFilter();
      }
    }
    if (this.inputField.value) {
      this.inputField.setValue(null);
    }
    this.triggerRequest.emit(true);
    this.countFiltersCategoriesApplied();
  }

  onSelectFilter(event: Event, filterKey: string): void {
    event.stopPropagation();
    this.selectedFilterKey = filterKey;
  }

  get inputField(): AbstractControl {
    return this.searchForm.get('inputField');
  }
}
